import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import Products from "./Products";

function ProductsOnePage() {
	const { settings, courses, category, setCategory } = useContext(AppContext);

	let sclollTimeOut;

	useEffect(() => {
		document.getElementById("main").addEventListener("scroll", handleScroll);

		return () => {
			document.getElementById("main")?.removeEventListener("scroll", handleScroll);
		};
	}, [courses, category]);

	const handleScroll = (e) => {
		clearTimeout(sclollTimeOut);
		sclollTimeOut = setTimeout(() => {
			if (settings.menu.categories_menu_type == 2) {
				console.log("handleScroll");
				let visibleCategory = false;
				for (const categoryBox of document.getElementsByClassName("category-box")) {
					const rect = categoryBox.getBoundingClientRect();
					if (rect.top < getContainerBottom() && rect.bottom > getContainerTop()) {
						visibleCategory = categoryBox;
						break;
					}
				}
				console.log(visibleCategory.id);
				if (visibleCategory) {
					console.log("change category by scroll to", visibleCategory);
					for (const course of courses) {
						for (const category of course.categories) {
							if ("category-" + category.id == visibleCategory.id) {
								setCategory(category);
								const element = document.querySelector(
									".menu-categories-2 .active"
								);
								console.log(element);
								if (element) {
									element.scrollIntoView({
										behavior: "smooth",
									});
								}
							}
						}
					}
				}
			}
		}, 100);
	};

	const getContainerTop = () => {
		const container = document.getElementById("main");
		if (container) {
			const rect = container.getBoundingClientRect();
			return rect.top;
		}
	};

	const getContainerBottom = () => {
		const container = document.getElementById("main");
		if (container) {
			const rect = container.getBoundingClientRect();
			return rect.bottom;
		}
	};

	return (
		<>
			{courses &&
				courses.map((course, i) => (
					<div key={i}>
						{course.name && <h3 className="course-title">{course.name}</h3>}
						{course.categories.map((cat, n) => (
							<div key={n} id={"category-" + cat.id} className="category-box">
								<Products products={cat.products} category={cat} />
							</div>
						))}
					</div>
				))}
		</>
	);
}

export default ProductsOnePage;
