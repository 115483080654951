import { useContext } from "react";
import { AppContext } from "../App";

function Tags({ product }) {
	const { getTranslatedString } = useContext(AppContext);

	return (
		<div className="tags">
			{product.tags &&
				product.tags.map((tag, i) => <div key={i}>{getTranslatedString(tag, "name")}</div>)}
		</div>
	);
}

export default Tags;
