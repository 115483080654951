import React, { useContext, useEffect, useState } from "react";

import Api from "./api/api";

import { AppContext } from "../App";
import Label from "./Label";

function Lists() {
	const { settings, shop, list, setList, brand, setLockList, lists, setLists } =
		useContext(AppContext);

	useEffect(() => {
		load_lists();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const load_lists = async () => {
		let request = {
			orderby: "position,name",
			src: [
				{
					name: "menu",
					value: 1,
					compare: "equal",
				},
				{
					name: "shop_id",
					value: shop.id,
					compare: "equal",
					type: "join",
					join_table: "lists_shops_relations",
					join_field: "list_id",
					src_field: "shop_id",
				},
			],
		};
		if (brand) {
			request.src.push({
				name: "brand_id",
				value: brand.id,
				compare: "equal",
			});
		}
		const response = await Api.post("/lists/list/", request);
		setLists(response.rows);
		if (settings.menu.hide_base_list == 1) {
			if (response.rows.length === 1) {
				setList(response.rows[0]);
				setLockList(true);
			}
		} else {
			if (response.rows.length === 0) {
				setList("none");
				setLockList(true);
			}
		}
	};

	return (
		<div className="main container-fluid shops-list">
			<h1>
				<Label number={3} />
			</h1>
			<div className="shops">
				{settings.menu.hide_base_list != 1 && (
					<div key={list.id} className="shop" onClick={() => setList("none")}>
						<button><h3>Listino base</h3></button>
					</div>
				)}
				{lists.map((list) => (
					<div key={list.id} className="shop" onClick={() => setList(list)}>
						<button>
							<h3>{list.name}</h3>
							{list.description != "" && <span>{list.description}</span>}
						</button>
					</div>
				))}
			</div>
		</div>
	);
}

export default Lists;
