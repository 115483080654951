import { useContext } from "react";
import { AppContext } from "../App";
import Allergens from "./Allergens";
import NumberFormat from "react-number-format";
import Label from "./Label";

function Product() {
	const { product, setProduct, getTranslatedString, shop } = useContext(AppContext);

	return (
		<div className="sheet">
			<div className="content">
				<div className="buttons-groups right">
					<button onClick={() => setProduct(false)}>
						<i className="fas fa-times"></i>
					</button>
				</div>

				<div className="scroll-content">
					{product.img && (
						<div className="img">
							<img src={product.img} alt={product.name} />
						</div>
					)}
					<div className="title">{getTranslatedString(product, "name")}</div>
					<div className="title">
						<NumberFormat
							value={product.price}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							suffix={shop.currency == "CHF" ? " CHF" : " €"}
							isNumericString={true}
						/>
					</div>
					<div className="container-fluid">
						<div
							className="description"
							dangerouslySetInnerHTML={{
								__html: getTranslatedString(product, "description"),
							}}
						/>
						<div className="row">
							{product.allergens.length > 0 && (
								<div className="col-sm-6">
									<div className="info-box">
										<h5 className="subtitle">
											<Label number={8} />
										</h5>
										<div className="info-box-content allergens">
											<Allergens
												allergens={product.allergens}
												color="#000"
												backgroundColor="#ccc"
											/>
										</div>
									</div>
								</div>
							)}
							{product.ingredients.length > 0 && (
								<div className="col-sm-6">
									<div className="info-box">
										<h5 className="subtitle">
											<Label number={9} />
										</h5>
										<div className="info-box-content ingredients">
											{product.ingredients.map((ingredient, i) => (
												<div key={i}>
													{getTranslatedString(ingredient, "name")} |
												</div>
											))}
										</div>
									</div>
								</div>
							)}
						</div>
						{product.variants_categories.length > 0 && (
							<div className="info-box">
								<h5 className="subtitle">
									<Label number={10} />
								</h5>
								<div className="info-box-content">
									{product.variants_categories.map((variants_category, i) => (
										<div key={i}>
											<div className="subtitle">
												{getTranslatedString(variants_category, "name")}
											</div>
											{variants_category.variants.map((variant, n) => (
												<div key={n} className="price-line">
													<div>
														{getTranslatedString(variant, "name")}
													</div>
													{variants_category.has_price == 1 && (
														<NumberFormat
															value={variant.price}
															displayType={"text"}
															thousandSeparator="."
															decimalSeparator=","
															suffix={
																shop.currency == "CHF"
																	? " CHF"
																	: " €"
															}
															isNumericString={true}
														/>
													)}
												</div>
											))}
											{variants_category.variants2.map((variant, n) => (
												<div key={n} className="price-line">
													<div>
														{getTranslatedString(variant, "name")}
													</div>
													{variants_category.has_price == 1 && (
														<NumberFormat
															value={variant.price}
															displayType={"text"}
															thousandSeparator="."
															decimalSeparator=","
															suffix={
																shop.currency == "CHF"
																	? " CHF"
																	: " €"
															}
															isNumericString={true}
														/>
													)}
												</div>
											))}
											{variants_category.products.map((subproduct, n) => (
												<div key={n} className="price-line">
													<div>
														{getTranslatedString(subproduct, "name")}
													</div>
													{variants_category.has_price == 1 && (
														<NumberFormat
															value={subproduct.price}
															displayType={"text"}
															thousandSeparator="."
															decimalSeparator=","
															suffix={
																shop.currency == "CHF"
																	? " CHF"
																	: " €"
															}
															isNumericString={true}
														/>
													)}
												</div>
											))}
										</div>
									))}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Product;
