import { useContext } from "react";
import { AppContext } from "../App";

function Home() {
	const { slides, getTranslatedString } = useContext(AppContext);

	const handleSlideAction = (slide) => {
		if (slide.action === "url" && slide.url) {
			window.open(slide.url);
		}
	};

	return (
		<div className="main slides">
			{slides &&
				slides.map((slide, i) => (
					<div
						key={i}
						style={{
							backgroundColor: slide.settings?.bg_color
								? slide.settings.bg_color
								: "none",
							backgroundImage:
								slide.content_alignment === "center"
									? "url(" + slide.img + ")"
									: "none",
							minHeight: slide.settings?.height
								? "calc(" + slide.settings.height + "dvh - var(--header-h))"
								: "auto",
						}}
						className={"slide " + slide.content_alignment + " slide-" + slide.id}
					>
						{slide.content_alignment !== "center" && slide.img && (
							<div
								className="img"
								style={{
									backgroundImage: "url(" + slide.img + ")",
								}}
							></div>
						)}
						{(slide.title || slide.text || slide.url) && (
							<div
								className="content"
								style={{
									backgroundColor: slide.settings?.bg_color
										? slide.settings.bg_color
										: "transparent",
									color: slide.settings?.text_color
										? slide.settings.text_color
										: "none",
								}}
							>
								{slide.title && (
									<div className="title">
										{getTranslatedString(slide, "title")}
									</div>
								)}
								{slide.text && (
									<div
										className="text"
										dangerouslySetInnerHTML={{
											__html: getTranslatedString(slide, "text"),
										}}
									/>
								)}
								{slide.action && (
									<button
										style={{
											backgroundColor: slide.settings?.btn_bg_color
												? slide.settings.btn_bg_color
												: "none",
											color: slide.settings?.btn_text_color
												? slide.settings.btn_text_color
												: "none",
										}}
										onClick={() => handleSlideAction(slide)}
									>
										{getTranslatedString(slide, "button_text")}
									</button>
								)}
							</div>
						)}
					</div>
				))}
		</div>
	);
}

export default Home;
